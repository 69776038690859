import React , { useEffect } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import Testimonail from "../components/testimonial";
//AOS
import AOS from "aos";
import "aos/dist/aos.css";
export default function Retailer() {

  useEffect(() => {

    AOS.init({
      
    });
    
  }, []);

  useEffect(() => {

    if(AOS){
      AOS.refresh();
    }
    
  });

  return (
    <div>
      <Helmet>
        <title>
          Retailer | OIC helps retailers to satisfy the demand of consumers
        </title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta
          name="title"
          content="Retailer | OIC helps retailers to satisfy the demand of consumers"
        />
        <meta
          name="description"
          content="OIC’s Premium Technology features aid retailers to be competitive in the optical industry to drive business. Assists in the satisfaction of consumer pride."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta
          property="og:description"
          content="OIC’s Premium Technology features aid retailers to be competitive in the optical industry to drive business. Assists in the satisfaction of consumer pride."
        />
      </Helmet>
      <Layout>
        <div
          className="bannerImage"
          style={{
            backgroundImage: `url("https://oicweb-media.s3.ap-south-1.amazonaws.com/retailer-banner.jpg")`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6 bannerTextPadder">
                <h1>
                  FOR YOUR<span> BETTER BUSINESS VISION</span> WITH
                  <span> OUR TECHNOLOGY</span>
                </h1>
                <a href="#" className="buttonStyleWhite hvr-forward">
                  Join Us
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60" style={{ background: "#ECEDEF" }}>
          <div className="container">
            <div className="row" data-aos="fade-left" data-aos-duration="400">
              <div className="col-md-12 text-center">
                <h2>
                  <span className="realistic-marker-highlight">Retailer</span>
                </h2>
                <p>
                  The retailer sells the goods to the customer. Retailers can
                  improve with OiC premium features to enhance their income with
                  the aid of enforcing POS, CRM and Shophub. Allow the retailer
                  to be profitable and to be at the pinnacle of its opponents
                  and to satisfy consumer pride. The retailer can choose the
                  vital capabilities on the OiC platform and use them for that
                  reason.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60">
          <div className="container">
            <div className="row" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-12 text-center">
                <h2>
                  <span className="realistic-marker-highlight">Features</span>
                </h2>
              </div>
              <div className="container IconBoxText">
                <div className="row featureglass">
                  <div className="featureglasses"><img src={require("../img/hp/Features_Glass.png")} /></div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsL" />
                    <div className="transitionRingsModifiedL" />
                    <div className="featureMarginCorrection IconBox blinkTransition">
                      <img src="https://oicweb-media.s3.ap-south-1.amazonaws.com/icons/icons-02.svg"></img>
                      <p className="transitionRetText">
                        {/* <strong>Marketplace </strong> */}
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc featureMarginTextCorrection">
                      Online platform to sell and buy optical products. In the
                      Marketplace the distributor will update the inventories to
                      show up for the retailer to buy them.
                    </p>
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsR" />
                    <div className="transitionRingsModifiedR" />
                    <div className="IconBox blinkTransition">
                      <img src="https://oicweb-media.s3.ap-south-1.amazonaws.com/icons/icons-02.svg"></img>
                      <p className="transitionRetText transitionDirection">
                        {/* <strong>Maintain Competitive Edge</strong> */}
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc">
                      Stand out from the competition by moving your business to
                      the digital era. OiC has been specially designed for the
                      optical industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <div className="padding60 blueBackground">
          <div className="container">
            <div className="row aligner" data-aos="fade-left" data-aos-duration="400">
              <div className="col-md-7 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/dashboard-screen.png"
                  className="img-responsive"
                ></img>
              </div>
              <div className="col-md-5">
                <h2 className="realistic-marker-highlight">Dashboard</h2>
                <p>
                  Affords an overview of marketplace, order, Try on, POS, CRM
                  with their features.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Start Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-7 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/dashboard-screen.png"
                  className="img-responsive"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60">
          <div className="container">
            <div className="row aligner" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-5">
                <h2 className="realistic-marker-highlight">Marketplace</h2>
                <p>
                  The platform embarks on and uploads its inventory to the stock
                  of the brands or distributors. Luxury and value-effective
                  goods under one roof. Our market has 10163+ products.
                  Furthermore, the supplier also can purchase merchandise from
                  distinct distributors. Over 15000 SKUs are in our library and
                  providers and retailers can place orders with the distributor.
                  In addition, the retailer can also buy goods from various
                  distributors.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Add Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-7">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/marketplace.jpg"
                  className="img-responsive"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60 blueBackground">
          <div className="container">
            <div className="row aligner" data-aos="fade-left" data-aos-duration="400">
              <div className="col-md-7 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/retailer-banner-01.jpg"
                  className="img-responsive"
                ></img>
              </div>
              <div className="col-md-5">
                <h2 className="realistic-marker-highlight">Order Placing</h2>
                <p>
                  Based on the inventories in the market, the store can area the
                  order with the distributor. In addition, the retailer also can
                  buy goods from diverse distributors.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Get Started
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-7 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/retailer-banner-01.jpg"
                  className="img-responsive"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60">
          <div className="container">
            <div className="row aligner" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-6">
                <h2>2D Try On</h2>
                <p>
                  Recognizes facial highlights in the clients photo and we
                  render the glasses at the clients face exactly and image the
                  whole thing being the same.
                </p>
                <br></br> <br></br>
              </div>
              <div className="col-md-6">
                <h2 className="realistic-marker-highlight">App Store</h2>
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 text-center">
                      <img
                        src="https://oicweb-media.s3.ap-south-1.amazonaws.com/app-01.png"
                        width="120"
                      ></img>
                    </div>
                    <div className="col-md-3 text-center">
                      <img
                        src="https://oicweb-media.s3.ap-south-1.amazonaws.com/app-02.png"
                        width="120"
                      ></img>
                    </div>
                    <div className="col-md-3 text-center">
                      <img
                        src="https://oicweb-media.s3.ap-south-1.amazonaws.com/app-03.png"
                        width="120"
                      ></img>
                    </div>
                    <div className="col-md-3 text-center">
                      <img
                        src="https://oicweb-media.s3.ap-south-1.amazonaws.com/app-04.png"
                        width="120"
                      ></img>
                    </div>
                    <div className="col-md-3 text-center">
                      <img
                        src="https://oicweb-media.s3.ap-south-1.amazonaws.com/app-05.png"
                        width="120"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Testimonail />
      </Layout>
    </div>
  );
}
